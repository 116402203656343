import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {selectAuthState, selectProfile} from '../features/auth/authSlice'

export const useAuth = () => {
    const authState = useSelector(selectAuthState);
    const profile = useSelector(selectProfile);

    return useMemo(() => ({ authState, profile }), [authState])
}


