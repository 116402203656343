import {DEFAULT_AUTH_STATE} from "../features/auth/authSlice";

const LOCAL_TOKEN = "local-token";
const WEB_DEVICE_ID = "Web-device-id";



const getLocalRefreshToken = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_TOKEN));
    return token?.refreshToken;
};

const getLocalAccessToken = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_TOKEN));
    return token?.token;
};

const updateLocalAccessToken = (source) => {
    let token = JSON.parse(localStorage.getItem(LOCAL_TOKEN));
    token.accessToken = source;
    localStorage.setItem(LOCAL_TOKEN, JSON.stringify(token));
};

const getToken = () => {
    return JSON.parse(localStorage.getItem(LOCAL_TOKEN)) || DEFAULT_AUTH_STATE;
};

const setToken = (token) => {
    localStorage.setItem(LOCAL_TOKEN, JSON.stringify(token));
};

const removeToken = () => {
    localStorage.removeItem(LOCAL_TOKEN);
};

const getDeviceId = () => {
    const deviceId = localStorage.getItem(WEB_DEVICE_ID) || `${Math.floor(Date.now() + Math.random())}`;
    localStorage.setItem(WEB_DEVICE_ID, deviceId);
    return deviceId;
};

const LocalTokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getToken: getToken,
    setToken: setToken,
    removeToken: removeToken,
    getDeviceId: getDeviceId
};

export default LocalTokenService;
