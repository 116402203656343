
import { createSlice } from "@reduxjs/toolkit";

export const SLICE_COMMON_NAME = "common";
export const commonSlice = createSlice({
    name: SLICE_COMMON_NAME,
    initialState: {
        ui:{
            collapseNav: localStorage.getItem("NavToggler.isNavCollapsed") === "true" || window.innerWidth < 720
        },
        loading: false,
        error: null,
    },
    reducers: {
        navToggle: (state, action) => {
            const ui = state.ui;
            localStorage.setItem("NavToggler.isNavCollapsed", !ui.collapseNav ? "true" : "false")
            ui.collapseNav = !ui.collapseNav;
        }
    }
});

export const { navToggle } = commonSlice.actions;

export default commonSlice.reducer;
