import React, {Suspense, useEffect, useState} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import MasterLayout from "../../components/Layout/MasterLayout/MasterLayout";
import LocalTokenService from "../../services/localTokenService";
import {bindActionCreators} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import {logOut} from "../../features/auth/authSlice"
import {Spin} from "antd";
import jwt_decode from "jwt-decode";
import {MenuRoles} from "../../components/Layout/MasterLayout/SideMenuItems";
import {
    CurrentUserHasRoles,
    IsArrayWithLength,
} from "../../utilities/extensions";


const ProtectedRoutes = ({children, other}) => {
    const dispatch = useDispatch();
    const authACs = bindActionCreators({logOut}, dispatch);
    const {authState: {token, refreshToken}} = useAuth();
    const isAuthenticated = token !== null;
    const navigate = useNavigate();
    const {pathname} = useLocation();

    useEffect(() => {
        const token = LocalTokenService.getLocalAccessToken();
        if (token) {
            const decodedJwt = jwt_decode(token);
            if (decodedJwt.exp * 1000 < Date.now()) {
                authACs.logOut();
                navigate(`/login`);
            }
        }
    }, [pathname]);

    let canAccess = (pathname === "/" || !IsArrayWithLength(MenuRoles[pathname]));
    if(IsArrayWithLength(MenuRoles[pathname])) {
        canAccess = CurrentUserHasRoles(MenuRoles[pathname]);
    }

    return <Suspense fallback={<Spin label="Page loading..."/>}>
        {
            isAuthenticated ?
                canAccess ? <MasterLayout /> : <Navigate to="/access-denied"/> :
                <Navigate to="/login"/>
        }
    </Suspense>
};
export default ProtectedRoutes;
