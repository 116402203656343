


// Check the length of the input
export const checkPasswordLength = (password)=>{
    if (password.length >= 12) {
       return true
     } else {
      return false;
     }
}
   // Check for special characters
export const checkSpecialCharacters = (password) => {
   const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
   if (pattern.test(password)) {
      return true;
   } else {
      return false;
   }
};


 // Check for an uppercase character
 export const checkUppercase = (password) => {
   const pattern = /[A-Z]/;
   if (pattern.test(password)) {
      return true;
   } else {
     return false;
   }
};

// Check for an lowercase character
export const checklowercase = (password) => {
   const pattern = /[a-z]/;
   if (pattern.test(password)) {
      return true;
   } else {
       return false;
   }
};

// Check for a number
export const checkNumber = (password) => {
   const pattern = /[0-9]/;
   if (pattern.test(password)) {
      return true;
   } else {
      return false;
   }
};
