import {notification} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import React from "react";
import {IsArrayWithLength, IsEmptyObject,IsNotEmpty} from "./extensions";

export const openNotificationWithIcon = (type,message, description) => {
    notification[type]({
        message: message,
        description:description,
    });
};
export const loadingIcon = <LoadingOutlined style={{ fontSize: 24, marginRight:'0.5em'}} spin />

export const SingleError = ({data, status}) =>{
    try {
        const {errors, title} = data;
        if(!IsEmptyObject(errors) && Object.keys(errors)[0] !== undefined){
            const fKey = Object.keys(errors)[0];
            return IsArrayWithLength(errors[fKey]) ? errors[fKey][0] : title || "Oh no, there was an error!";
        }
        return title || "Oh no, there was an error!";
    }catch{
        return "Oh no, there was an error!";
    }
}

export const JsonTryParse = (data, defaultValue) =>{
   try {
       return JSON.parse(data);
   }catch{
       return defaultValue;
   }
}
