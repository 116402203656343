import React, {useState, createContext, useContext} from "react";
import {Spin} from "antd";

const defaultValues = Object.freeze({
    show:false,
    label: null,
    ariaLive:'polite',
    labelPosition:'bottom',
    styles: null,
    className: 'spinner-provider',
    showSpinner: () => {}
});


export const SpinnerContext = createContext(defaultValues);

export const SpinnerProvider = ({ children }) => {

    //const {dispatch, subscribe, getState} = useStore();
    //const [show, setShow] = useState(defaultValues);

    const [show, setShow] = useState(defaultValues);

    const showSpinner = (values) => setShow({...defaultValues,...values});

    const spinnerValue = { ...show, showSpinner };

    return (
        <SpinnerContext.Provider value={spinnerValue}>
            {children}
            {
                spinnerValue?.show &&
                <Spin onClick={e=>showSpinner({show:false})} />

            }
        </SpinnerContext.Provider>
    );
}

export const useSpinnerProvider = () => useContext(SpinnerContext);
