import {createApi, BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError,} from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "./auth/authSlice";
import {RootState} from "./appStore";
import { Mutex } from "async-mutex"
import {UserResponse} from "../models/UserResponse";
import {GLOBAL_CONFIG} from "../global/constants";

export const TenantId = "root"
export const BaseApiUrl = GLOBAL_CONFIG.BASE_API_URL;
const baseQuery = fetchBaseQuery({
    baseUrl: BaseApiUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        headers.set("tenant", TenantId);
        return headers
    }});

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
const mutex = new Mutex()
const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refreshResult = await baseQuery("/refresh", api, extraOptions)
                if (refreshResult.data) {
                    const profile = (api.getState() as RootState).auth.profile;
                    api.dispatch(setCredentials({...(refreshResult.data) as UserResponse, ...profile }));
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    api.dispatch(logOut())
                }
            }finally {
                release();
            }
        }
        else{
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result
};

export const baseApiService = createApi({ baseQuery: baseQueryWithReAuth, endpoints: builder => ({}) })
